import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-014737e8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "scrollbar-flex-content"
};
export function render(_ctx, _cache) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    style: {
      "width": "200px"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(50, item => {
      return _createElementVNode("p", {
        key: item,
        class: "scrollbar-demo-item"
      }, _toDisplayString(item), 1);
    }), 64))])]),
    _: 1
  });
}